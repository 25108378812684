@mixin align-cargo-self () {
  width: inherit;
  height: auto;
  display: flex;
  gap: 1rem;
  word-wrap: break-word;
  overflow: hidden;
}

.mesa_partes-wrapper{
  display: grid;
  width: inherit;
  grid-template-rows: repeat(2, auto);
  align-items: center;
  
}

.mesa_partes-wrapper .form{
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mesa_partes-wrapper .stepper {
  padding-top: 30px;
  width: inherit;
}

.mesa_partes-wrapper .form-container {
  width: 80%;
  // min-height: 240px;
  display: grid;
  grid-template-rows: repeat(3, auto);
}

.mesa_partes-wrapper .form-container .hover-message {
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, auto);
}

.mesa_partes-wrapper .form-container .form-class {
  width: 1152px !important;
  float: left;
}

.mesa_partes-wrapper .form-container .body {
  width: inherit;
}

.mesa_partes-wrapper .form-container .footer {
  width: inherit;
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  align-items:center;
  justify-content: space-evenly;
}

.mesa_partes-wrapper .form-container .footer .footer-button{
  //grow > how much |shrink > how much |basis > initial length
  // flex-grow: 0rem;
  // flex-shrink: 5rem;
  flex-basis: 13rem;
  // margin-left: 8.25rem;
  // margin-right: 8.25rem;
}
/* -------------------------------------- */
/* Componentes que forman parte del body */
/* -------------------------------------- */
.mesa_partes-wrapper .form-container .body .datos-remitente{
  width: inherit;
  display: grid;
  grid-template-rows: repeat(3,auto);
  grid-template-areas:  "datos-generales"
                        "datos-contacto"
                        "validacion";
  }

.mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales{
  width: auto;
  grid-area: datos-generales;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr 1fr 5% 5% 5% 5% 5% 1fr 1fr;//repeat(5, 1fr);
  grid-template-areas:  "s-doc s-doc ..... ..... ..... ..... ..... i-num i-num"
                        "i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-mod";
}

.mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales-cons{
  grid-template-areas:  "s-doc s-doc ..... ..... ..... ..... ..... i-num i-num"
                        "i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso";
}

    .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales .select-documento{
      width: 100%;
      grid-area: s-doc;
    }

    .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales .input-numero{
      width: 100%;
      grid-area: i-num;
    }

    .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales .input-nombre{
      width: 100%;
      grid-area: i-rso;
    }

    .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales .input-modification{
      width: 100%;
      grid-area: i-mod;
      margin-top: 2.5rem;
      height: 38px;
    }

.mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto{
  width: auto;
  grid-area: datos-contacto;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr 1fr 5% 5% 5% 5% 5% 1fr 1fr;
  grid-template-areas:  "i-cel  i-cel  ...... ...... ...... ...... ...... i-tel  i-tel"
                        "i-mail i-mail i-mail i-mail i-mail ...... ...... i-sms  i-sms";
}

    .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-celular{
      width: 100%;
      grid-area: i-cel;
    }

    .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-telefono{
      width: 100%;
      grid-area: i-tel;
      display: flex;
    }
        .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-telephone{
          width: 60%;
          grid-area: i-tel;
        }
        .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-anexo{
          width: 40%;
          margin-top: 2.5rem;
        }

    .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-email{
      width: 100%;
      grid-area: i-mail;
    }

        .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-email .email-helper{
          display: flex;
          align-items: stretch;
          text-align: justify;
        }
        .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-email .email-button{
          width: 7.875rem;
        }

    .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-sms{
      width: 100%;
      grid-area: i-sms;
    }

.mesa_partes-wrapper .form-container .body .datos-remitente .validacion{
  width: auto;
  grid-area: validacion;
}


.mesa_partes-wrapper .form-container .body .datos-documento {
  width: inherit;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:  "relacion-documento"
                        "documentos";
}

.mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento {
  width: auto;
  grid-area: relacion-documento;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-template-areas:  "b-exp b-exp b-exp b-exp b-exp"
                        "mi-num mi-num . . ."
                        "ta-content ta-content ta-content ta-content ta-content";
}

    .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento .botones-seleccion {
      width: 100%;
      grid-area: b-exp;
      padding-bottom: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

        .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento .botones-seleccion .select-button {
          //grow > how much |shrink > how much |basis > initial length
          flex-basis: 28.75rem;
          width: inherit;
        }

    .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento .num_expediente {
      width: 100%;
      grid-area: mi-num;
    }

    .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento .asunto {
      width: 100%;
      grid-area: ta-content;
    }

.mesa_partes-wrapper .form-container .body .datos-documento .documentos {
  width: auto;
  grid-area: documentos;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-areas:  "b-principal"
                        "b-anexos";
}

    .mesa_partes-wrapper .form-container .body .datos-documento .documentos .doc_principal {
      width: 100%;
      padding-bottom: 1rem;
      grid-area: b-principal;
    }

    .mesa_partes-wrapper .form-container .body .datos-documento .documentos .doc_anexos {
      width: 100%;
      grid-area: b-anexos;
    }


.mesa_partes-wrapper .form-container .body .cargo {
  width: inherit;
  
}
.mesa_partes-wrapper .form-container .body .resumen-datos {
  width: auto;
  display: grid;
  gap: 0.5rem 0rem;
}
.mesa_partes-wrapper .form-container .body .resumen-datos .numero-doc {
  @include align-cargo-self()
}
.mesa_partes-wrapper .form-container .body .resumen-datos .nombre {
  @include align-cargo-self()
}
.mesa_partes-wrapper .form-container .body .resumen-datos .correo-electronico {
  @include align-cargo-self()
}
.mesa_partes-wrapper .form-container .body .resumen-datos .datos-contacto {
  @include align-cargo-self();
  gap: 0rem 1rem !important;
  flex-direction: row;
  flex-basis: 100%;
  align-items: flex-start;
  flex-wrap: wrap;
}
  .mesa_partes-wrapper .form-container .body .resumen-datos .datos-contacto .celular{
    @include align-cargo-self();
  }
  .mesa_partes-wrapper .form-container .body .resumen-datos .datos-contacto .telefono{
    @include align-cargo-self();
  }
  .mesa_partes-wrapper .form-container .body .resumen-datos .datos-contacto .anexo{
    @include align-cargo-self();
  }
.mesa_partes-wrapper .form-container .body .resumen-datos .nro-expediente {
  @include align-cargo-self()
}

/* -------------------------------------- */
/* Overrides de componentes por form de BOOTSTRAP*/
/* -------------------------------------- */
@media screen and (max-width: 1440px) {
  .mesa_partes-wrapper .form-container .form-class {
    width: 816px !important;
  }
  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales {
  grid-template-areas:  "s-doc s-doc s-doc ..... ..... ..... i-num i-num i-num"
                        "i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-mod";
  }
  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales-cons {
  grid-template-areas:  "s-doc s-doc s-doc ..... ..... ..... i-num i-num i-num"
                        "i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso";
  }
  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto {
  grid-template-areas:  "i-cel  i-cel  i-cel  ...... ...... ...... i-tel  i-tel  i-tel"
                        "i-mail i-mail i-mail i-mail i-mail ...... i-sms  i-sms  i-sms";
  }

  .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento .botones-seleccion {
    flex-direction: column;
    padding-bottom: 0.5rem;
  }

  .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento .botones-seleccion .select-button {
    //grow > how much |shrink > how much |basis > initial length
    flex-basis: 3.5rem;
    width: inherit;
  }

  .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento {
    grid-template-areas:  "b-exp b-exp b-exp b-exp b-exp"
                          "mi-num mi-num mi-num . ."
                          "ta-content ta-content ta-content ta-content ta-content";
  }
}

@media screen and (max-width: 1020px) {
  .mesa_partes-wrapper .form-container .form-class {
    width: 640px !important;
  }
  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales {
    grid-template-rows: repeat(3, auto);
    grid-template-areas:  "s-doc s-doc s-doc ..... ..... ..... i-num i-num i-num"
                          "i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso"
                          "..... ..... ..... ..... ..... ..... ..... i-mod i-mod";
  }
      .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales .input-modification {
        margin-top: -1rem;
      }
  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales-cons {
    grid-template-areas:  "s-doc s-doc s-doc s-doc ..... i-num i-num i-num i-num"
                          "i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso i-rso";
  }

  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto {
    grid-template-rows: repeat(3, auto);
    grid-template-areas:  "i-cel  i-cel  i-cel  i-cel  ...... i-tel  i-tel   i-tel   i-tel"
                          "i-mail i-mail i-mail i-mail i-mail i-mail i-mail  i-mail  i-mail"
                          "i-sms  i-sms  i-sms  i-sms  i-sms  i-sms  i-sms   i-sms   i-sms";
  }
      .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto .input-sms {
        padding-top: 0rem;
      }
}

@media screen and (max-width: 800px) {
  .mesa_partes-wrapper .form-container .form-class {
    width: 528px !important;
  }
  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr 1fr;
    grid-template-areas:  "s-doc s-doc"
                          "i-num i-num"
                          "i-rso i-rso"
                          "..... i-mod";
  }
  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-generales-cons {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 1fr;
    grid-template-areas:  "s-doc"
                          "i-num"
                          "i-rso";
  }

  .mesa_partes-wrapper .form-container .body .datos-remitente .datos-contacto {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr;
    grid-template-areas:  "i-cel"
                          "i-tel"
                          "i-mail"
                          "i-sms";
  }

  .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento {
    grid-template-areas:  "b-exp b-exp b-exp b-exp b-exp"
                          "mi-num mi-num mi-num mi-num ."
                          "ta-content ta-content ta-content ta-content ta-content";
  }
}

@media screen and (max-width: 660px) {
  .mesa_partes-wrapper .form-container .form-class {
    width: 384px !important;
  }
  .mesa_partes-wrapper .form-container .footer {
    flex-direction: column;
    padding-bottom: 1.5rem;
  }

  .mesa_partes-wrapper .form-container .footer .footer-button {
    flex-basis: 3.5rem;
    width: inherit;
  }

  .mesa_partes-wrapper .form-container .body .datos-documento .relacion-documento {
    grid-template-areas:  "b-exp b-exp b-exp b-exp b-exp"
                          "mi-num mi-num mi-num mi-num mi-num"
                          "ta-content ta-content ta-content ta-content ta-content";
  }
}