.navbar-main {
  height: inherit;
  padding-right: 25px;
  padding-left: 25px; 

}
.navbar-logo {
  padding:0px;
}
.navbar-container {
  overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.navbar-component {
  width: 100%;
  height: inherit;
  margin-left: auto;
  margin-right: auto;
}