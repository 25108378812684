.main-popup{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-template-areas:  "header"
                        "body"
                        "footer";
  padding: 1rem 1.875rem 1rem 1.875rem;
  background-color: white !important;
}

.main-popup .popup-header{
  width: inherit;
  grid-area: header;
}

.main-popup .popup-header .modal-title{
  width: 100%;
}
.main-popup .popup-header .modal-header{
  --bs-modal-header-padding: 0rem !important;
}
.main-popup .popup-header .popup-title{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-popup .popup-body{
  grid-area: body;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.main-popup .popup-body .modal-body {
  --bs-modal-padding: 0rem !important;
}

.main-popup .popup-footer{
  grid-area: footer;
}
.main-popup .popup-footer .modal-footer {
  --bs-modal-padding: 0rem !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.main-popup .popup-footer .cancel-button {
  width: 11.25rem;
}
.main-popup .popup-footer .accept-button {
  width: 11.25rem;
}