.main-title{
  position: relative;
  padding-left: 25px;
  padding-top: 25px;
  padding-right: 25px;
  z-index: 1;
}

.title-button{
  width: 3rem;
  margin-bottom: 2rem;
}

.hr-title {
  margin-top: 1.25rem;
  margin-bottom: 0rem;
  border-color: #FF9929;
  opacity: 1;
  width: 10rem !important;
  border-radius: 25px;
  border-top: 20px solid #FF9929;
}
.hr-title-expand {
  width: 100% !important;
}

.wrap-subtitle {
  padding-top: 3.75rem;
  display: flex;
  justify-content: space-between;
}

.wrap-subtitle .subtitle-text {
  width: fit-content;
}
.wrap-subtitle .subtitle-button {
  width: 11.25rem;
}

#bootstrap-overrides .btn {
  width: 2rem;
}