.circles {
  position: absolute;
  right: 0;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  width: 224px;
  justify-content: flex-end;
}

.circles .circles-circle {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  border: 4px solid var(--bs-secondary);
}

.main-background{
  height: 100%;//839.5px
  width: 100%;
  background-color: var(--bs-primary);
  background-size: 100% auto;
}
