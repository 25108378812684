.stepWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;  
  align-items: flex-start;
  // margin-bottom: 20px;
}

.stepWrapper .step{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.stepWrapper .line-separator-end{
  width: 100%;
  margin-left: 280px !important;
  height: 4px;
  top: 0px;
  bottom: 0px;
  background-color: #004EA8;
  margin-top: -7.813rem;
}
.stepWrapper .line-separator-start{
  width: 100%;
  margin-right: 280px !important;
  height: 4px;
  top: 0px;
  bottom: 0px;
  background-color: #004EA8;
  margin-top: -7.813rem;
}

@media screen and (max-width: 1440px) {
  .stepWrapper .line-separator-end {
    margin-left: 210px !important;
  }
  .stepWrapper .line-separator-start {
    margin-right: 210px !important;
  }
} 

@media screen and (max-width: 1020px) {
  .stepWrapper .line-separator-end {
      margin-left: 170px !important;
  }

  .stepWrapper .line-separator-start {
    margin-right: 170px !important;
  }
}

@media screen and (max-width: 800px) {
  .stepWrapper .line-separator-end {
    margin-left: 150px !important;
  }

  .stepWrapper .line-separator-start {
    margin-right: 150px !important;
  }
}

@media screen and (max-width: 660px) {
  .stepWrapper .line-separator-end {
    margin-left: 120px !important;
  }

  .stepWrapper .line-separator-start {
    margin-right: 120px !important;
  }
}