.subform-fieldset{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: inherit;
  border: 1px solid #667892;
  border-radius: 5px;
  //display: flex;
}

.subform-fieldset .fieldset-wrapper{
  //display: flex;
  width: inherit;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.subform-fieldset legend {
  all: revert;
  margin-left: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

/* PARA EL MANEJO CUSTOM DE ALGUNOS VALORES DE LOS ELEMENTOS DE HTML */

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* PARA LAS VALIDACIONES DINÁMICAS DE LOS DISTINTOS CAMPOS*/
input:invalid[focused="true"] {
  border: 1px solid rgba(var(--bs-danger-rgb),1) !important;
}
input:invalid[focused="true"] ~ .invalid-div{
  display: block;
}
input:invalid[focused="true"] ~ .valid-div{
  display: none;
}
// input[type="checkbox"]:invalid[focused="true"] {
//   border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
// }

input:valid[focused="true"] ~ .valid-div {
  display: block; 
}

.invalid-div {
  display: none;
}

.input-read-only{
  background-color: #e9ecef !important;
}

textarea:invalid[focused="true"] {
  border: 1px solid rgba(var(--bs-danger-rgb),1) !important;
}
textarea:invalid[focused="true"] ~ .invalid-div{
  display: block;
}
textarea:invalid[focused="true"] ~ .valid-div{
  display: none;
}
textarea:valid[focused="true"] ~ .valid-div {
  display: block; 
}

select:invalid[focused="true"] {
  border: 1px solid rgba(var(--bs-danger-rgb),1) !important;
}
select:invalid[focused="true"] ~ .invalid-div{
  display: block;
}
select:invalid[focused="true"] ~ .valid-div{
  display: none;
}

select:valid[focused="true"] ~ .valid-div {
  display: block;
}
.invalid-div {
  display: none;
}

/* STYLES FOR THE VARIOUS COMPONENTS */
.react-tel-input .flag-dropdown{
  border: 1px solid rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.react-tel-input .country-list{
  height: inherit !important;
  font-size: 1rem !important;
  border-radius: 0.375rem !important;
  border: 1px solid #ced4da !important;
  z-index: 1;
  list-style: none;
  position: inherit !important; 
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.35) !important;
  background-color: white;
  overflow-y: scroll;
}

.react-tel-input .form-control{
  width: inherit !important;
  height: 38px !important;
  font-size: 1rem !important;
  border-radius: 0px !important; 
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

//Evitar el focus de los componente PhoneInput
.react-tel-input .flag-dropdown:focus,
.react-tel-input .form-control:focus {
  background-color: white !important;
}

//Ahora para el controlInput
// input.form-control {
//   background-color: white !important;
//   border-color: #042352 !important;  /* cambiar esto al color que desees */
// }

input.form-control:focus {
  background-color: white !important;
  border-color: #042352 !important;  /* cambiar esto al color que desees */
}

textarea.form-control {
  background-color: white !important;
}

.subform-fieldset .margin-bottom {
  margin-bottom: 1rem;
}
.checkbox-text {
  width: inherit;
  display: flex;
  align-items: center;
  //grow > how much |shrink > how much |basis > initial length  
}
.checkbox-text .checkbox-text-checkbox{
  flex: 0 0 1.875rem;
  padding-left: 0.25rem;
}
.checkbox-text .checkbox-text-text{
  flex: 0 0 inherit;
}
.helper-text{
  width: 100%;
  margin-top: -0.5rem;
  text-align: end;
  background-color: white !important;
}

.verification-sms { 
  margin-bottom: -0.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:  "input codigo"
                        "helper helper";
  background-color: white !important;
}
.verification-sms-active { 
  grid-template-areas:  "input codigo"
                        "helper helper";
  background-color: white !important;
}
.verification-sms-unactive { 
  grid-template-areas:  "input input"
                        "helper helper";
}

    .verification-sms .codigo{
      grid-area: codigo;
      padding-left: 1rem;
      background-color: white !important;
    }
    .verification-sms .input{
      grid-area: input;
      padding-top: 0rem;
      background-color: white !important;
    }
    .verification-sms .helper{
      margin-top: -1.25rem;
      grid-area: helper;
    }

.split-input .flex-inputs{
  display: flex;
  gap: 0.5rem;
  height: fit-content;
  padding-bottom: 1.25rem;
  justify-self: center;
} 

.split-input .flex-inputs .input{
  width: 100%;
  height: inherit;
} 

.split-input .flex-inputs .score{
  width: auto;
  height: 100%;
  align-items: center;
} 

.split-input .helper{
  margin-top: -1.25rem;
} 

.text-container-area textarea{
  resize: none;
}

.file-submission{
  width: inherit;
  display:grid;
  grid-template-columns: 1fr 7.625rem;
  grid-template-rows: repeat(2,auto);
  grid-template-areas:  "label button"
                        "files files";
}

  .file-submission .files-label{
    grid-area: label;
    
  }
  .file-submission .files-button{
    height: 1.75rem;
    padding-left: 1rem;
    grid-area: button;
  }
    .file-submission .files-button .button-components{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
  .file-submission .files-list {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    grid-area: files;
    width: 100%;
    height: fit-content;
  }


/*      CUSTOM OVERRIDES        */
.override-text-center{
  text-align:center;
  background-color: white !important;
}

.override-input-celular  .form-control{
  border-radius: 0.375rem !important;
  background-color: white !important;
}
.override-input-telephone  .form-control{
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
  border-right: 0px !important;
  background-color: white !important;
}
.override-input-numero{
  border-radius: 0.375rem !important;
  background-color: white !important;
}

.override-input-anexo{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background-color: white !important;
}

//Colores blanco de los componentes

//Del select
select.custom-select {
  background-color: white;
}

//Del checkbox
input[type='checkbox'] {
  background-color: white;
}