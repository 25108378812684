.cards-wrapper-min{
  padding-top: 5.625rem !important;
}
.card-flex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0rem;
  margin-right: 1.5rem;
  margin-left: 1.5em;
}
.cards-wrapper-max{
  padding-top: 3.75rem !important;
}

.card-wrapped{
  margin:0; 
}

@media screen and (max-width: 1440px) {
  .card-flex {
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 4rem;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 800px) {
  .card-flex {
      flex-direction: column;
    }
}

@media screen and (max-width: 660px) {
  .wrapper {
    width: 480px !important;
    max-width: 480px !important;
  }
}