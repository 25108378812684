.hr-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-color: white;
  opacity: 1;
  border-top: 3px solid white;
}

.card-max{
  height:20.5rem !important;
  width:100% !important;
  --bs-card-border-radius: 30px;
  border-radius: var(--bs-card-border-radius);
}
.card-min{
  height:13.625rem !important;
  width:100% !important;
  --bs-card-border-radius: 30px;
  border-radius: var(--bs-card-border-radius);
}

//Grid Styleout
.wrapper-max{
  width: 23.125rem !important;
  display:flex;
  flex-direction: column;
  row-gap: 3.25rem;
}

.wrapper-min{
  width: 29.25rem !important;
  display: flex;
  flex-direction: column;
  gap: 3.25rem;
}

//Styleout -> for components structure

.title{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.icon{
  height: 1.8rem;
}
// import boostrap styles at the bottom
@import "~bootstrap/scss/bootstrap";

@media screen and (max-width: 1020px) {
  .wrapper-max {
    width: 30rem !important;
  }

  .card-max {
    height: 16rem !important;
  }
}

@media screen and (max-width: 660px) {
  .wrapper-max {
    width: 23.125rem !important;
  }

  .wrapper-min {
    width: 24rem !important;
  }

  .card-max {
    height: 17rem !important;
  }
}