.list-ul {
  width: inherit;
  //min-height: 2.75rem;
  max-height: 17rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
}

.list-group-item {
  border: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: fit-content !important;
}

.file-item {
  display: flex;
  width: inherit;
  height: 1.125rem;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  background-color: white;
}

.file-item .file-name {
  height: fit-content;
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
  gap: 1rem;
  overflow: hidden;
}

.file-item .file-name .file-text {
  height: fit-content;
  overflow: hidden;
}

.file-item .file-name .file-size {
  flex-basis: 6rem;
  display: inline;
}

.file-item .icons {
  display: flex;
  justify-content: center;
  width: fit-content;
  gap: 0.5rem;
  flex-flow: row-reverse;
}

.file-item .bin-icon {
  width: 2rem;
  height: 2rem;
  padding-bottom: 0.1rem;
  align-items: center;
}

.file-item .download-icon {
  width: 2rem;
  height: 2rem;
  padding-bottom: 0.1rem;
  display: flex;
  align-items: center;
}

.override-file-item {
  border: 1px solid var(--bs-secondary) !important;
  border-radius: 30px !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  background-color: white !important;
  // margin-left: 1rem;
  // margin-right: 1rem;
}