.circle {
  width: 3.625rem;
  height: 3.625rem;
  background-color: #667892;
  color: white;
  border-radius: 50%;
  // border: 2px solid transparent;
  cursor: default;
  -moz-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepBlock .circleWrapper {
  padding: 0px;
  position: relative;
  display: flex;
  justify-content: center;
}

.selected .circle {
  color: white;
  background-color: #004EA8;
  border-color: #004EA8;
}

// .stepBlock:not(:last-child)>.circleWrapper::after {
//   content: '';
//   width: 100%;
//   height: 4px;
//   position: absolute;
//   top: 0px;
//   bottom: 0px;
//   background-color: #004EA8;
//   margin: auto;
//   z-index: -1;
// }

.stepBlock:not(:last-child)>.circleWrapper::after {
  height: 4px;
}

.stepBlock:not(:last-child).selected>.circleWrapper::after,
.stepBlock:not(:last-child).selected~.stepBlock:not(:last-child)>.circleWrapper::after {
  height: 4px;
  opacity: 0.5;
}