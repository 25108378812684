@mixin round ($width, $height){
  width: $width !important;
  height: $height !important;
}

.btn-rounded{
  @include round(100%, 3rem);
}

.btn-rounded-selection{
  @include round(100%, 3rem);
  opacity: 80% !important;
}

.btn-rounded-primary{
  @include round(100%, 3.5rem);
  display:flex;
  padding: 0.75rem !important;
}

.btn-link{
  @include round(100%, 100%);
  padding-top: 0px !important;
  margin-top: -0.5rem !important;
  --bs-btn-line-height: 1rem !important;
}

.btn-close{
  @include round(100%, 100%);
  position: relative !important;
  padding-top: 0px !important;
  padding: 0px !important;
  margin-top: -0.25rem ;
}

/*        OVERRIDES           */

.override-btn-outline-primary {
  @include round(100%, 100%);
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  --bs-btn-line-height: 1rem !important;
}

.override-btn-outline-icon{
  @include round(100%, 100%);
  --bs-btn-hover-border-color: var(--bs-secondary) !important;
  .btn:hover {
      border-color: var(--bs-secondary) !important;
  }
  padding: 0rem !important;
  --bs-btn-line-height: 1rem !important;
}

.override-btn-link-modify {
  margin-top: -0.25rem !important;
  padding-right: 0rem !important;
  text-align: center !important;
}

//For a disable component -> button
.disabled{
  background-color: #667892 !important;
  border-color: #667892 !important;
}