.wrapper {
  // height: 100%;
  // width: 100%;
  width: 1440px; 
  max-width: 1440px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-rows: 110px auto;
  grid-template-areas:  "app-navbar"
                        "app-component";
} 

.wrapper .app-navbar{
  height: 110px;
  width: inherit;
  margin: 0px;
  // z-index: 999;
  grid-area: app-navbar;
}

.wrapper .app-component{
  // position: absolute;
  // bottom: 0;
  width: inherit;
  height: bottom;
  // z-index: -999;
  position: relative;
  margin: 0px;
  grid-area: app-component;
}

/* Luego quitarlo */

body{
  --bs-body-bg: #042352 !important;
  //background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%2392b7f0' fill-opacity='0.43'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

@media screen and (max-width: 1440px) {
	.wrapper {
    width: 1020px !important;
    max-width: 1020px !important;
	}
}
@media screen and (max-width: 1020px) {
	.wrapper {
    width: 800px !important;
    max-width: 800px !important;
	}
}
@media screen and (max-width: 800px) {
	.wrapper {
    width: 660px !important;
    max-width: 660px !important;
	}
}
@media screen and (max-width: 660px) {
	.wrapper {
    width: 480px !important;
    max-width: 480px !important;
	}
}