// overwrite theme variables

// Color themes

$primary: #042352;
$secondary: #004EA8;
$info: #FF9929;
$success: #ADD754;
$warning: #FF9929;
$danger: #E23008;
$light: #A5B0C1;
$dark: #667892;
$white: #FFFFFF;

$theme-colors:(
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  white: $white,
) !default;

//Other components
$btn-border-radius: 1.875rem;
$btn-disabled-opacity: 100%;

//Font- family
$font-family-sans-serif: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
$font-family-serif: Roboto, Georgia, "Times New Roman", Times, serif;
$font-family-monospace: Roboto, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$h1-font-size: 2rem !important;
$h2-font-size: 1.5rem !important;
$h3-font-size: 1.125rem !important;
$h4-font-size: 1rem !important;
$h5-font-size: 0.75rem !important;
$h6-font-size: 0.625rem !important;
$btn-font-size: 1.3rem !important;
$btn-font-weight: 600 !important;

// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
// $theme-colors: (
  //   "secondary-blue": #004EA8
  // );
  
// import boostrap styles at the bottom
@import "~bootstrap/scss/bootstrap";

//Font customization
h1 {
  font-size: 2rem !important;
  font-weight: 700 !important;
  line-height: 2rem !important;
}

h2 {
  font-size: 1.75rem !important;
  font-weight: 600 !important;
  line-height: 1.75rem !important;
}

h3 {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
}

h4 {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
}

h5 {
  font-size: 1.125rem !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important;
}

b {
  font-weight: 700 !important;
}

p {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  display: inline;
}

/* Media Querys -> Font resize */

@media screen and (max-width: 660px) {
  h1 {
    font-size: 1.75rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
  }

  h3 {
    font-size: 1.25rem !important;
  }

  h4 {
    font-size: 1.125rem !important;
  }

  h5 {
    font-size: 1rem !important;
  }

  p {
    font-size: 0.8rem !important;
  }
}