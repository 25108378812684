.alert{
  --bs-alert-padding-y: 0.25rem !important;
  margin-top:1rem;
  justify-content: flex-start;
  border-radius: 0px 4px 4px 0px!important;
  gap:1rem;
  padding: 0px 1rem 0px 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.alert-info{
  --bs-alert-color: #004EA8 !important;
  --bs-alert-bg: #9EC3EE !important;
  --bs-alert-border-color: #9EC3EE !important;
}

.alert .alert-padding{
  width:6px;
  height: 100%;
  background-color: currentColor;
}

.alert .alert-content{
  display: grid;
  grid-template-columns: 26px 1fr 26px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
  gap: 1rem;
}
.alert .alert-content .alert-icon{
  
}
.alert .alert-content h5{
  margin-bottom: 0.125rem !important;
}

.alert .alert-content .alert-label{
  margin-top:0rem;
  width: 100%;
}

.alert .alert-content .alert-button{
  height: 26px;
  width: 100%;
}